import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { component as Footer } from "@layout/footer"
import { component as Header } from "@layout/header"
import Layout from "@layout/no-header-footer"
import Grid from "@layout/grid"
import Jumbotron from "@components/jumbotron"
import Carousel from "@components/carousel"
import Stat from "@components/stat"
import Card from "@components/card"
import Image from "@components/image"

class HomePage extends React.Component {
  // data alias
  page = this.props.data.datoCmsHomePageTruckVan

  constructor(props) {
    super(props)

    this.state = { width: 0 }
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener("resize", (e) => this.handleResize(e))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize() {
    this.setState({ width: document.body.clientWidth })
  }

  getProductsSection() {
    const cards = this.page.products
    const isOdd = cards.length % 2 !== 0
    const firstRowAmt = Math.floor(cards.length / 2)
    let firstRow = []
    const secondRow = []

    if (this.state.width > 600) {
      for (let i = 0; i < cards.length; i++) {
        if (i < firstRowAmt) {
          firstRow.push(cards[i])
        } else {
          secondRow.push(cards[i])
        }
      }
    } else {
      firstRow = cards
    }

    return (
      <>
        <div>
          <Grid items={`${firstRow.length}`} hasChildren gutter="15" layout={`${firstRowAmt}`}>
            {firstRow.map((c, index) => {
              const card = c.product[0]
              const cardData = {
                ...card,
                image: {
                  childImageSharp: {
                    gatsbyImageData: {
                      ...card.image.gatsbyImageData
                    }
                  }
                },
                theme: "media-image-size-constant",
                url: card.link
              }
              return <Card key={index} {...cardData} />
            })}
          </Grid>
        </div>
        <div style={{ marginTop: "30px" }}>
          <Grid
            items={`${secondRow.length}`}
            hasChildren
            gutter="15"
            layout={`${isOdd ? firstRowAmt + 1 : firstRowAmt}`}
          >
            {secondRow.map((c, index) => {
              const card = c.product[0]
              const cardData = {
                ...card,
                image: {
                  childImageSharp: {
                    gatsbyImageData: {
                      ...card.image.gatsbyImageData
                    }
                  }
                },
                theme: "media-image-size-constant",
                url: card.link
              }
              return <Card key={index} {...cardData} />
            })}
          </Grid>
        </div>
      </>
    )
  }

  getProductTypesSection() {
    const cards = this.page.productTypes

    return (
      <div style={{ marginTop: "80px" }}>
        <Grid items={`${cards.length}`} hasChildren gutter="15" layout="2">
          {cards.map((card, index) => {
            const cardData = {
              ...card,
              image: {
                childImageSharp: {
                  gatsbyImageData: {
                    ...card.image.gatsbyImageData
                  }
                }
              },
              theme:
                card.theme === "Background Image" ? "background-image" : card.theme.toLowerCase(),
              url: card.link
            }
            return <Card key={index} {...cardData} />
          })}
        </Grid>
      </div>
    )
  }

  getBrandsCarousel() {
    const brands = this.page.brandsCarousel
    return (
      <div style={{ marginTop: "120px" }}>
        <Carousel theme={brands.theme} title={brands.title}>
          {brands.slides.map((slide, index) => {
            const image = {
              childImageSharp: {
                gatsbyImageData: {
                  ...slide.image.gatsbyImageData
                }
              }
            }
            return <Image key={index} src={image} link={slide.linkTo} />
          })}
        </Carousel>
      </div>
    )
  }

  getVideoSection() {
    const videoSection = this.page.videoSection
    const jumbotron = {
      backgroundImage: {
        childImageSharp: {
          gatsbyImageData: {
            ...videoSection.backgroundImage.gatsbyImageData
          }
        }
      },
      body: videoSection.body,
      buttons: videoSection.buttons,
      cards: videoSection.cards,
      theme: videoSection.theme,
      video: videoSection.video
    }

    return (
      <div style={{ marginBottom: "-12px", marginTop: "80px" }}>
        <Jumbotron {...jumbotron}></Jumbotron>
      </div>
    )
  }

  render() {
    const jumbotron = {
      backgroundImage: {
        childImageSharp: {
          gatsbyImageData: {
            ...this.page.jumbotron.backgroundImage.gatsbyImageData
          }
        }
      },
      body: this.page.jumbotron.body,
      heading: this.page.jumbotron.heading
    }

    return (
      <>
        <Helmet>
          <html lang="en" />
          <meta name="facebook-domain-verification" content="qhz20a3vot5gvufv49kb9yehq634tk" />
        </Helmet>
        <Header {...this.props.data.menu} />

        <div style={{ marginTop: "75px" }}>
          <Jumbotron {...jumbotron}>
            <Grid items={`${this.page.jumbotronStats.length}`} hasChildren gutter="15" layout="3">
              {this.page.jumbotronStats.map((stat, index) => {
                return <Stat key={index} body={stat.body} metric={stat.metric} />
              })}
            </Grid>
          </Jumbotron>
        </div>

        <Layout seoMetaTags={this.page.seoMetaTags} useTriangleBackground={true}>
          {this.getProductsSection()}
          {this.getProductTypesSection()}
          {this.getBrandsCarousel()}
        </Layout>

        {this.getVideoSection()}

        <Footer {...this.props.data.footer} />
      </>
    )
  }
}

export default HomePage

export const query = graphql`
  query HomePageTruckVanQuery {
    ...MegaMenu
    ...Footer

    datoCmsHomePageTruckVan {
      permalink
      title
      seoMetaTags {
        tags
      }
      jumbotron {
        backgroundImage {
          alt
          url
          title
          gatsbyImageData
        }
        buttons {
          label
          link
          theme
        }
        body
        heading
      }
      jumbotronStats {
        body
        metric
      }
      products {
        product {
          body
          image {
            gatsbyImageData
          }
          heading
          buttonLabel
          link
          theme
        }
      }
      productTypes {
        body
        image {
          gatsbyImageData
        }
        heading
        buttonLabel
        link
        theme
      }
      brandsCarousel {
        theme
        title
        slides {
          ... on DatoCmsImageOld {
            linkTo
            image {
              gatsbyImageData
            }
          }
          ... on DatoCmsContent {
            body
          }
        }
      }
      videoSection {
        theme
        body
        video {
          videoUrl
          videoEmbedCode
        }
        backgroundImage {
          gatsbyImageData
        }
        buttons {
          label
          link
          theme
        }
        cards {
          body
        }
      }
    }
  }
`
