import React from "react"
import PropTypes from "prop-types"

import * as styles from "./styles.module.scss"

const Stat = ({ body, metric }) => {
  return (
    <div className={styles.stats}>
      <div>
        <h3>{metric}</h3>
        <p>{body}</p>
      </div>
    </div>
  )
}

Stat.propTypes = {
  body: PropTypes.string,
  metric: PropTypes.string
}

Stat.defaultProps = {}

export default Stat
